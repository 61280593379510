<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import SettingsSection from 'dashboard/components/SettingsSection.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    SettingsSection,
  },
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      wsUrl: '',
      sipHost: '',
    };
  },
  validations: {
    wsUrl: { required },
    sipHost: { required },
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const { sip_host, ws_url } = this.inbox;
      this.sipHost = sip_host;
      this.wsUrl = ws_url;
    },
    async updateInbox() {
      try {
        this.loading = true;
        let payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            sip_host: this.sipHost,
            ws_url: this.wsUrl,
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        useAlert(this.$t('INBOX_MGMT.PHONE.EDIT.SUCCESS_MESSAGE'));
      } catch (error) {
        useAlert(error.message);
      }
    },
  },
};
</script>

<template>
  <div class="mx-8">
    <SettingsSection
      :title="$t('INBOX_MGMT.PHONE.TITLE')"
      :sub-title="$t('INBOX_MGMT.PHONE.SUBTITLE')"
    >
      <form @submit.prevent="updateInbox">
        <div class="mb-6">
          <woot-input
            v-model="sipHost"
            :class="{ error: v$.sipHost.$error }"
            class="max-w-[75%] w-full"
            :label="$t('INBOX_MGMT.PHONE.SIP_DOMAIN.LABEL')"
            :placeholder="$t('INBOX_MGMT.PHONE.SIP_DOMAIN.PLACE_HOLDER')"
            @blur="v$.sipHost.$touch"
          />
          <woot-input
            v-model="wsUrl"
            type="text"
            :class="{ error: v$.wsUrl.$error }"
            class="max-w-[75%] w-full"
            :label="$t('INBOX_MGMT.PHONE.WS_URL.LABEL')"
            :placeholder="$t('INBOX_MGMT.PHONE.WS_URL.PLACE_HOLDER')"
            @blur="v$.wsUrl.$touch"
          />
        </div>
        <woot-submit-button
          :button-text="$t('INBOX_MGMT.PHONE.UPDATE')"
          :loading="uiFlags.updateInbox"
          :disabled="v$.$invalid || uiFlags.isUpdating"
        />
      </form>
    </SettingsSection>
  </div>
</template>
