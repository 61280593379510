<script>
import { useAlert } from 'dashboard/composables';
import FormButton from 'v3/components/Form/Button.vue';
import { mapGetters } from 'vuex';
import { hasPushPermissions } from 'dashboard/helper/pushHelper';
export default {
  components: {
    FormButton,
  },
  props: {},
  data() {
    return {
      enteredUsername: '',
      enteredPassword: '',
      inputStyles: {
        borderRadius: '12px',
        padding: '6px 12px',
        fontSize: '14px',
        marginBottom: '2px',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'userPhoneCredentials/getUIFlags',
      credentials: 'userPhoneCredentials/getCredentials',
    }),
    inboxes() {
      return this.$store.getters['inboxes/getInboxes'];
    },
  },
  validations: {},
  watch: {
    credentials(record) {
      this.enteredUsername = record.username;
    },
  },
  mounted() {
    if (hasPushPermissions()) {
      this.getPushSubscription();
    }
    this.$store.dispatch('userPhoneCredentials/get');
  },
  methods: {
    async updateCredentials() {
      let alertMessage = '';
      try {
        await this.$store.dispatch('userPhoneCredentials/update', {
          username: this.enteredUsername,
          password: this.enteredPassword,
        });
        alertMessage = this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS');
      } catch (error) {
        alertMessage = this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_ERROR');
      } finally {
        useAlert(alertMessage);
      }
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="updateCredentials()">
    <woot-input
      v-model="enteredUsername"
      :styles="inputStyles"
      :label="$t('PROFILE_SETTINGS.FORM.PHONE_SETTINGS.USERNAME')"
    />
    <woot-input
      v-model="enteredPassword"
      type="password"
      :styles="inputStyles"
      :label="$t('PROFILE_SETTINGS.FORM.PHONE_SETTINGS.PASSWORD')"
    />
    <FormButton
      type="submit"
      color-scheme="primary"
      variant="solid"
      size="large"
      :disabled="uiFlags.isUpdating"
    >
      {{ $t('PROFILE_SETTINGS.FORM.PHONE_SETTINGS.SUBMIT') }}
    </FormButton>
  </form>
</template>
