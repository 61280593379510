<script>
export default {
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <div v-for="card in cards" :key="card.id" class="message-text__wrap">
    <div class="text-content">
      <h3>{{ card.title }}</h3>
      <template v-for="action in card.actions">
        <p v-if="action.type === 'link'" :key="action.uri">
          <a :href="action.uri">{{ action.text }}</a>
        </p>
      </template>
    </div>
  </div>
</template>
