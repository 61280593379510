<script>
export default {
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <div v-for="article in articles" :key="article.id" class="message-text__wrap">
    <div class="text-content">
      <a :href="article.link">{{ article.title }}</a>
      {{ article.description }}
    </div>
  </div>
</template>
